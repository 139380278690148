import React from "react";
import { Col, Row } from "react-bootstrap";
import logoFooter from '../assets/img/logo_footer.png';
function Footer() {
    return (
    
    <div className="footer text-center text-lg-start text-white">
        <a className="icon-whatsapp" href="https://wa.me/5511937210111?text=Olá%2C%20entrei%20em%20contato%20através%20do%20site%20www.mslaw.adv.br." 
        target="_blank" rel="noreferrer" title="Fale Conosco">
        <span></span>
        </a>
        <section id="contato" className="justify-content-center p-4 border-bottom">
        <Row>
            <Col xs={12} sm={12} md={6} lg={4} className="fooCol">
            <div className="me-5 d-lg-block">
                <img src={logoFooter} className="logo-footer" alt="" />
            </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} className="fooCol">
            <div className="end">
                <p>Rua Fiandeiras, 827 - Sala 21 - Itaim Bibi, São Paulo - SP, 04545-005</p>
                <p>+55 11 93721-0111</p>
                <p>tributario@mslaw.adv.br</p>
            </div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={4} className="fooCol">
            <div className="social">

                <a href="https://www.linkedin.com/in/sergio-marques-de-souza-filho-1758071b3/" className="me-4 text-reset linkedin">
                </a>
                <a href="mailto:sergio@mslaw.adv.br" target='_blank' rel="noreferrer" className="me-4 text-reset google ">
                </a>
                <a href="tel:+5511937210111" className="me-4 text-reset phone">
                </a>

            </div>

            </Col>
        </Row>
        </section>

    </div>
    );
}

export default Footer;