import Carousel from 'react-bootstrap/Carousel';
import CarouselMulti from 'react-multi-carousel';
import { useMediaQuery } from "@uidotdev/usehooks";
import { Col, Row, Container, Button } from "react-bootstrap";

import banner from '../assets/img/img_banner.png';
import bannerMob from '../assets/img/img_banner_mob.png';
import civil from '../assets/img/img-civil.png';
import trabalhista from '../assets/img/img-trabalhista.png';
import ambiental from '../assets/img/img-ambiental.png';
import administrativo from '../assets/img/img-administrativo.png';
import criminal from '../assets/img/img-criminal.png';
import tributario from '../assets/img/img-tributario.png';
import financeiro from '../assets/img/img-financeiro.png';
import img1dou from '../assets/img/img1dou-rec.png';
import img2dou from '../assets/img/img2dou-rec.png';
import img3dou from '../assets/img/img3dou-rec.png';
import img4dou from '../assets/img/img4dou-rec.png';
import img5dou from '../assets/img/img5dou-rec.png';
import img6dou from '../assets/img/img6dou-rec.png';
import imgcombustivel from '../assets/img/img-combustivel.png';
import imgbebidas from '../assets/img/img-bebidas.png';
import imghigiene from '../assets/img/img-higiene.png';
import imgauto from '../assets/img/img-auto.png';
import imgfarmacos from '../assets/img/img-farmacos.png';
import imgpneu from '../assets/img/img-pneu.png';
import imgcigarro from '../assets/img/img-cigarro.png';
import imgcosmeticos from '../assets/img/img-cosmeticos.png';
import imgposto from '../assets/img/img-posto.png';
import imgbar from '../assets/img/img-bar.png';
import imgimportadora from '../assets/img/img-importadora.png';
import imglojaauto from '../assets/img/img-lojaauto.png';
import imgqrcode from '../assets/img/qrcode_whats.png';
import imgpneubaterias from '../assets/img/img-pneubaterias.png';
import imgtransportada from '../assets/img/img-transportada.png';
import imgsupermercado from '../assets/img/img-supermercado.png';
import imgfarmacia from '../assets/img/img-farmacia.png';
import imgcaneta from '../assets/img/img-caneta.png';
import imgcifrao from '../assets/img/img-cifrao.png';
import imgcadeia from '../assets/img/img-cadeia.png';
import imgalvo from '../assets/img/img-alvo.png';
import iconbe from '../assets/img/icon-be.png';
import linkedinW from '../assets/img/icone_linkedin.png';

const AddressMap = () => {
  return (

    <div className="google-map-code">
      <div className="p_article">
        <iframe title="Endereço" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.246394400183!2d-46.68424472516027!3d-23.595494762846222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce574fb4c15c75%3A0x27b82370e69f377!2sRua%20Fiandeiras%2C%20827%20-%20Vila%20Ol%C3%ADmpia%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004545-005!5e0!3m2!1sen!2sbr!4v1698936408900!5m2!1sen!2sbr" width="600" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
      </div>
    </div>
  );
}
const CarouselBn = () => {
  const mobileScreen = useMediaQuery("only screen and (max-width : 820px)")
  const img_banner = mobileScreen ? bannerMob : banner;
  return (

    <div className="">
      <div className='divBanner'>
        <div className={mobileScreen ? "item_mob" : "item_desk"} >
          <Carousel slide={false}>

            <Carousel.Item >
              <Carousel.Caption>
                <h3>Experiência, credibilidade e transparência</h3>
                <div id="fourth" className="button-box">
                  <Button variant="link" href="#recuperacao" title="Saiba Mais">Saiba Mais</Button>

                </div>
              </Carousel.Caption>
              <img
                className="d-block w-100"
                src={img_banner}
                alt=""
              />

            </Carousel.Item>


          </Carousel>
        </div>
     
      </div>

    </div>
  );
}


const AboutUs = () => {
  const mobileScreen = useMediaQuery("only screen and (max-width : 820px)")
  return (
    <div className="about">

      <div className="p_article">
        <p id="sobre" className="p_title dourado">Sobre {/*<div className=""></div>*/} </p>
        <Row className="row_about">
          <Col xs={12} md={12} lg={5} >
            <div className={mobileScreen ? "img_about_mobile" : "img_about"}></div>
          </Col>

          <Col xs={12} md={12} lg={5} className="about_text">

            <p className="text">A Marques de Souza Advocacia e Consultoria Tributária, foi fundada com o intuito de resguardar os direitos e interesses dos clientes em face do panorama fiscal predatório a que somos submetidos.
            </p>
            <p className="text">Atuando no mercado desde 2005, com vasta experiência, contamos com profissionais capacitados que utilizam dos mais modernos sistemas e informativos. </p>
            <p className="text">Nosso objetivo é a reestruturação de empresas, para que se tornem competitivas no seu ramo de atuação.</p>
            <Button variant="link" href="https://www.linkedin.com/in/sergio-marques-de-souza-filho-1758071b3/"> <img src={linkedinW} alt="" /> </Button>

          </Col>
        </Row>

      </div>
    </div>
  );
};


const Areas = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const CustomLeftArrow = ({ onClick }) => (
    <div className="custom-left-arrow" onClick={onClick}>
    </div>
  );

  const CustomRightArrow = ({ onClick }) => (
    <div className="custom-right-arrow" onClick={onClick}>
    </div>
  );

  return (
    <div className="areas">

      <div className="p_article">
        <p id="atuacao" className="p_title dourado">Áreas de atuação  {/*<div className=""></div>*/}</p>


        <CarouselMulti
           responsive={responsive}
           infinite={true}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
         containerClass="carousel-container"
        >

          <div> <img
            draggable={false}
            style={{ width: "100%", cursor: "pointer" }}
            src={civil}
            alt=""
          />
            <p className="title">Civil</p>
          </div>
          <div> <img
            draggable={false}
            style={{ width: "100%", cursor: "pointer" }}
            src={trabalhista}
            alt=""
          />
            <p className="title">Trabalhista</p>
          </div>
          <div> <img
            draggable={false}
            style={{ width: "100%", cursor: "pointer" }}
            src={ambiental}
            alt=""
          />
            <p className="title">Ambiental</p>
          </div>

          <div>  <img
            draggable={false}
            style={{ width: "100%", cursor: "pointer" }}
            src={administrativo}
            alt=""
          />
            <p className="title">Administrativo</p>
          </div>

          <div> <img
            draggable={false}
            style={{ width: "100%", cursor: "pointer" }}
            src={criminal}
            alt=""
          />
            <p className="title">Criminal</p>
          </div>
          <div> <img
            draggable={false}
            style={{ width: "100%", cursor: "pointer" }}
            src={tributario}
            alt=""
          />
            <p className="title">Tributario</p>
          </div>

          <div> <img
            draggable={false}
            style={{ width: "100%", cursor: "pointer" }}
            src={financeiro}
            alt=""
          />
            <p className="title">Financeiro</p>
          </div>


        </CarouselMulti>

      </div>
    </div>
  );
}

const Recovery = () => {
  return (
    <div className="recovery">

      <div className="p_article">
        <p id="recuperacao" className="p_title dourado">Recuperação de Impostos  {/*<div className=""></div>*/}</p>
        <p className="p_sub">Aproximadamente R$ 250 bilhões são os impostos pagos indevidamente no Brasil</p>
        <Row className="icons_box">

          <Col xs={6} md={4} className="box-rec">
            <img src={img1dou} alt="" />
            <p className="text">No Brasil existem mais de 19.373.275 empresas</p>
          </Col>
          <Col xs={6} md={4} className="box-rec">
            <img src={img2dou} alt="" />
            <p className="text">95% delas pagam mais impostos do que deveriam</p>
          </Col>
          <Col xs={6} md={4} className="box-rec">
            <img src={img3dou} alt="" />
            <p className="text">Nossa solução possibilita intensificar e melhorar o fluxo de caixa das empresas</p>
          </Col>


          <Col xs={6} md={4} className="box-rec">
            <img src={img4dou} alt="" />
            <p className="text">Em média 32 instruções normativas são criadas por dia</p>
          </Col>
          <Col xs={6} md={4} className="box-rec">
            <img src={img5dou} alt="" />
            <p className="text">Meses de faturamento são "perdidos" com o acúmulo de impostos pagos a maior nos últimos 5 anos</p>
          </Col>
          <Col xs={6} md={4} className="box-rec" >
            <img src={img6dou} alt="" />
            <p className="text">Aproximadamente 34% sobre o faturamento das empresas é pago em impostos</p>
          </Col>


        </Row>


      </div>
    </div>
  );
};

const Benefits = () => {
  return (
    <div className='benefits'>

      <div className="p_article">
        <p id="beneficios" className="p_title dourado">Benefícios  Alcançados  {/*<div className=""></div>*/}</p>
        <Row className="icons_box">

          <Col className="check" xs={3} md={3}>
            <img src={iconbe} alt="" />
          </Col>
          <Col xs={8} md={9}>
            <p className="text branco">Processo 100% administrativo e eletrônico</p>
          </Col>

          <Col className="check" xs={3} md={3}>
            <img src={iconbe} alt="" />
          </Col>
          <Col xs={8} md={9}>
            <p className="text branco">Ressarcimento, restituição ou compensação de créditos tributários</p>
          </Col>

          <Col className="check" xs={3} md={3}>
            <img src={iconbe} alt="" />
          </Col>
          <Col xs={8} md={9}>
            <p className="text branco">Conhecimento dos direitos em relação aos benefícios fiscais</p>
          </Col>

          <Col className="check" xs={3} md={3}>
            <img src={iconbe} alt="" />
          </Col>
          <Col xs={8} md={9}>
            <p className="text branco">Reclassificação fiscal</p>
          </Col>

          <Col className="check" xs={3} md={3}>
            <img src={iconbe} alt="" />
          </Col>
          <Col xs={8} md={9}>
            <p className="text branco">Custas somente no êxito</p>
          </Col>

          <Col className="check" xs={3} md={3}>
            <img src={iconbe} alt="" />
          </Col>
          <Col xs={8} md={9}>
            <p className="text branco">Pioneirismo no segmento</p>
          </Col>


        </Row>


      </div>

    </div>


  );
}

const Products = () => {
  return (
    <div className='products'>
      <div className="p_article">

        <Carousel >
          <Carousel.Item>
            <p className="p_title">Os produtos que contemplam os benefícios do regime monofásico são:</p>
            <Row className="icons_box">
              <Col xs={6} md={3}>
                <img src={imgcombustivel} alt="" />
                <p className="text">Combustível</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgbebidas} alt="" />
                <p className="text">Bebidas Frias</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imghigiene} alt="" />
                <p className="text">Higiene Pessoal</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgauto} alt="" />
                <p className="text">Auto Peças</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgfarmacos} alt="" />
                <p className="text">Fámacos</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgpneu} alt="" />
                <p className="text">Pneu</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgcigarro} alt="" />
                <p className="text">Cigarro</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgcosmeticos} alt="" />
                <p className="text">Cosméticos</p>
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item>
            <p className="p_title two">Os setores que contemplam os benefícios do regime monofásico são:</p>
            <Row className="icons_box">
              <Col xs={6} md={3}>
                <img src={imgposto} alt="" />
                <p className="text">Postos, TRR e distribuidores de combustíveis</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgbar} alt="" />
                <p className="text">Bares, Restaurantes e Lojas de Conveniência</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgimportadora} alt="" />
                <p className="text">Importadoras</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imglojaauto} alt="" />
                <p className="text">Revendedores de Autopeças</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgpneubaterias} alt="" />
                <p className="text">Loja de Bateria e Pneus</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgtransportada} alt="" />
                <p className="text">Transportadoras</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgsupermercado} alt="" />
                <p className="text">Supermercados</p>
              </Col>
              <Col xs={6} md={3}>
                <img src={imgfarmacia} alt="" />
                <p className="text">Farmácia e Produtos de beleza</p>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>

  );

}

const Stage = () => {
  return (
    <div className='stage'>
      <div className="p_article">
        <p id="etapas" className="p_title azul">Etapas</p>
        <div className="timeline-container">
          <ul>
            <li>
              <div className="st_1">
                <p className="text st_title_1">Assinatura de contrato</p>
                <small><img src={imgcaneta} alt="" /></small>
                <p className="sub_title_1"> NDA dando sigili das informações
                  Procuração digital para captura dos dados
                  Apresentação do Estudo
                  Assinatura do contrato</p>
              </div>
            </li>

            <li>
              <div className="st_2">
                <p className="text st_title_2">Gestão dos créditos</p>
                <small><img src={imgcifrao} alt="" /></small>
                <p className="sub_title_2"> Solicitação
                  Transmissão
                  Compensação
                  Retificações
                  Novas apurações</p>
              </div>
            </li>

            <li>
              <div className="st_3">
                <p className="text st_title_3">Cadeias de abastecimento</p>
                <small><img src={imgcadeia} alt="" /></small>
                <p className="sub_title_3">Relatórios de consumo dos créditos
                  Cobrança de prestação do serviço conforme consumo dos créditos
                  Informes sobre as mudanças de regras</p>
              </div>
            </li>
            <li>
              <small className="ult"><img src={imgalvo} alt="" /></small>
            </li>

          </ul>
        </div>
      </div>
    </div>
  );
}

const Contact = () => {
  return (
    <div className='contact'>

      <div className="p_article">
        <p id="contato" className="p_title dourado">Parceria e Contato</p>
        <p className="p_sub">Como podemos ajudar? Entre em contato conosco.</p>
        <Row className="icons_box">

        <Col xs={6} sm={6} md={6} lg={3}>
          
          <p className='title_con dourado'>E-mail</p>
          <p className="text">tributario@mslaw.adv.br</p>
          <p className='title_con dourado'>Endereco</p>
          <p className="text">Rua Fiandeiras, 827, sala 21 - Itaim Bibi - SP</p>
          
        </Col>

          <Col xs={6} sm={6} md={6} lg={3}>
            <p className='title_con dourado'>Whatsapp</p>
            <p className="text">+55 11 93721-0111</p>
            <p className="text">Atendimento em dias úteis de 9h a 18h</p>
           
          </Col>
          <Col xs={6} sm={6} md={6} lg={3}>
            <img src={imgqrcode} alt="" />
          </Col>
          <Col xs={6} sm={6} md={6} lg={3}>
          <p className="text">É só apontar a câmera do seu celular para o QR code ao lado.</p>
          </Col>
       
        </Row>

      </div>

    </div>
  )
}


function MainContent() {
  return (
  <main >
   <CarouselBn />
   <Container fluid className="p-0">
      <AboutUs />
      <Areas />
      <Recovery />
      <Benefits />
      <Products />
      <Stage />
      <Contact />
      <AddressMap />

    </Container>
   </main>
  );
}

export default MainContent;