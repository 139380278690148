import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import './App.css';
import './assets/script/home.js';
import './assets/style/home.css?v=1';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';

function App() {
  return (
    <div className="Home">
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default App;