import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import logo from '../assets/img/logo_header.png';
import { useWindowDimensions } from 'react-native';


const HeaderC = (props) => {
    const { width } = useWindowDimensions();
    const className = width <= 768 ? 'mobile' : 'desktop';
    return (
      <header>
  
    <Nav className={"screen-" + className} >
          <div className="box-nav w-100">
            <div className="navbar-header">
              <a className="navbar-brand" href="/#">
                <img src={props.brand} className="logo" alt="Marques de Souza Adivogados & Consultores" /></a>
            </div>
            <div id="menu">
              {props.navMenuMobile}
              {props.navMenu}
            </div>
          </div>
        </Nav>
  
      </header>
    );
  };

  const MenuMobile = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
  
    return (
      <div className="menumobile">
        <button
          className={isNavExpanded ? "hamburger open" : "hamburger"}
          onClick={() => {
            setIsNavExpanded(!isNavExpanded)
          }}
        >
  
          <span className={isNavExpanded ? "menu-button__lines--open" : "menu-button__lines"}>
            <span className="menu-button__line"></span>
            <span className="menu-button__line"></span>
            <span className="menu-button__line"></span>
          </span>
        </button>
        <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"} >
        <Nav.Link href="#sobre">Sobre nós</Nav.Link>
        <Nav.Link href="#atuacao">Áreas de Atuação</Nav.Link>
        <Nav.Link href="#recuperacao">Recuperação Tributária</Nav.Link>
        <Nav.Link href="#contato">Contato</Nav.Link>
        </div>
      </div>
    )
  }

  const Menu = () => {
    return (
      <div className="menu">
        <Nav.Link className="text-dark active" href="#sobre">Sobre</Nav.Link>
        <Nav.Link className="text-dark" href="#atuacao">Áreas de Atuação</Nav.Link>
        <Nav.Link className="text-dark" href="#recuperacao">Recuperação Tributária</Nav.Link>
        <Nav.Link className="text-dark" href="#contato">Parceria e Contato</Nav.Link>
      </div>
  
    );
  };

function Header() {
const [activeClass, setActiveClass] = React.useState(false);
return (
    
      <HeaderC
        setActiveClass={setActiveClass}
        activeClass={activeClass}
        brand={logo}
        navMenu={<Menu />}
        navMenuMobile={< MenuMobile />}
      />

  );
}

export default Header;