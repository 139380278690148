//import Cookies from 'js-cookie';
function reveal() {
  const reveals = document.querySelectorAll(".p_title div");
  for (let i = 0; i < reveals.length; i++) {
    const windowHeight = window.innerHeight;
    const elementTop = reveals[i].getBoundingClientRect().top;
    const elementVisible = 150;
    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("on");
    } else {
      reveals[i].classList.remove("on");
    }
  }
  // const cookieAlert = document.querySelector(".cookie-alert");
  // if (!Cookies.get('acceptCookies') && cookieAlert) {
  //     cookieAlert.classList.add("show");
  // }

}

window.addEventListener("scroll", reveal);

// To check the scroll position on page load
reveal();

document.addEventListener("scroll", (event) => {
	
	var lastPosition = window.scrollY; 

  if (lastPosition > 400 ) {
		document.querySelector('.about').classList.add('animar');
  } else {
    document.querySelector('.about .p_title').classList.remove('animar');
	}
  if (lastPosition > 800 ) {
		document.querySelector('.areas').classList.add('animar');
	} else {
		document.querySelector('.areas').classList.remove('animar');
	}

  if (lastPosition > 1400 ) {
		document.querySelector('.recovery').classList.add('animar');
	} else {
		document.querySelector('.recovery').classList.remove('animar');
	}

  if (lastPosition > 2100 ) {
		document.querySelector('.benefits').classList.add('animar');
	} else {
		document.querySelector('.benefits').classList.remove('animar');
	}

  if (lastPosition > 2800 ) {
		document.querySelector('.products').classList.add('animar');
	} else {
		document.querySelector('.products').classList.remove('animar');
	}

  if (lastPosition > 3600 ) {
		document.querySelector('.stage').classList.add('animar');
	} else {
		document.querySelector('.stage').classList.remove('animar');
	}
  if (lastPosition > 4800 ) {
		document.querySelector('.contact').classList.add('animar');
	} else {
		document.querySelector('.contact').classList.remove('animar');
	}

  if (lastPosition > 500 ) {
		document.querySelector('.about .p_title').classList.add('exibir');
	} else {
		
    document.querySelector('.about .p_title').classList.remove('exibir');
	}
  if (lastPosition > 900 ) {
		document.querySelector('.areas .p_title').classList.add('exibir');
	} else {
		document.querySelector('.areas .p_title').classList.remove('exibir');
	}
  if (lastPosition > 1600 ) {
		document.querySelector('.recovery .p_title').classList.add('exibir');
	} else {
		document.querySelector('.recovery .p_title').classList.remove('exibir');
	}

  if (lastPosition > 2300 ) {
		document.querySelector('.benefits .p_title').classList.add('exibir');
	} else {
		document.querySelector('.benefits .p_title').classList.remove('exibir');
	}

  if (lastPosition > 3000 ) {
		document.querySelector('.products .p_title.two').classList.add('exibir');
	} else {
		document.querySelector('.products .p_title.two').classList.remove('exibir');
	}
  if (lastPosition > 3000 ) {
		document.querySelector('.products .p_title').classList.add('exibir');
	} else {
		document.querySelector('.products .p_title').classList.remove('exibir');
	}

  if (lastPosition > 3800 ) {
		document.querySelector('.stage .p_title').classList.add('exibir');
	} else {
		document.querySelector('.stage .p_title').classList.remove('exibir');
	}
  if (lastPosition > 5000 ) {
		document.querySelector('.contact .p_title').classList.add('exibir');
    document.querySelector('.contact .p_sub').classList.add('exibir');
  } else {
		document.querySelector('.contact .p_title').classList.remove('exibir');
    document.querySelector('.contact .p_sub').classList.remove('exibir');
	}

  })

const menu =  document.querySelector('button.hamburger');
if(menu){

  if(document.querySelector('.menumobile button').classList.contains('open')){
          
    menu.classList.remove('fade-out')
    menu.classList.add('fade-in')
  }else{
        
    menu.classList.remove('fade-in')
    menu.classList.add('fade-out')
  }
  
}


window.addEventListener('scroll', function () {
  const headerEl = document.querySelector('header');
  let scroll = this.scrollY;
  // var windowHeight = window.innerHeight;
  if (scroll > 254) {
    // document.querySelector('header').style.height = "50px"; 
    document.querySelector('header').classList.add("scroll_");
    return;
  } else {
    document.querySelector('header').classList.remove("scroll_");
  }
  if (scroll > 254) return;

  const defaultHeight = 121;

  let newHeight = defaultHeight - scroll / 6;
  // if (newHeight < 60){
  //   newHeight = 60;
  // } 
  newHeight = Math.max(newHeight, 60);
  headerEl.style.height = newHeight;

});

/*
function scrollToTop(scrollDuration) {
  var scrollStep = -window.scrollY / (scrollDuration / 15),
    scrollInterval = setInterval(function(){
    if ( window.scrollY != 0 ) {
        window.scrollBy( 0, scrollStep );
    }
    else clearInterval(scrollInterval); 
},15);
} 
*/



// Smooth scrolling to any internal tags
//var elem_ = document.querySelectorAll('#menu a[href*=#]:not([href=#])');
//document.querySelectorAll("#menu a[href*=#']")[0].href;

const elem_ = document.querySelectorAll('#menu a');
for (let index = 0; index < elem_.length; index++) {

  elem_[index].addEventListener('click', () => {

    if (window.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
      window.location.hostname === this.hostname) {
      let target = document.querySelector(this.hash);
      if(target){
        target = target.length ? target : document.querySelector('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          document.querySelector('html,body').animate({
            scrollTop: target.offset().top - 10
          }, 500);
  
          return false;
        }
      }
    }
  });


}
// const cookieAlert = document.querySelector(".cookie-alert");
// setTimeout(() => {

//     if (window.innerWidth < 720) {
  
//     document.querySelector('#item_mob').style.display = "block";
//     document.querySelector('#item_desk').style.display = "none";
//   } else {
  
//     if (document.querySelector('#item_desk')) {
//       document.querySelector('#item_desk').style.display = "block";
//     }
//     if (document.querySelector('#item_mob')) {
//       document.querySelector('#item_mob').style.display = "none";
//     }
//   }



//   if (!Cookies.get('acceptCookies') && cookieAlert) {
//       cookieAlert.classList.add("show");
//   }

// }, 200)




